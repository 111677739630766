@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:100,300,400,500');

$break-xs: 544px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$break-xl: 1200px;

$color-dark:#000;
$color-light:#FFF;

$color-alt:#cebda3;
$color-alt-light:#e2d7c8;


$background-lighter:#FFF;
$background-light:#f5f2ed;

$background-dark:#1A1A1A;

$background-buttons:#6b9b7b;


@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}


html{
	font-size: 16px;
	scroll-behavior: smooth;
	height:100%;
}

body{
	font-family: 'Alegreya Sans', arial, sans-serif;
	font-size:16px;
	font-weight : 300;
	line-height: 200%;
	height:100%;
}

h1,h2,h3,h4{
	font-family: 'Alegreya Sans', sans-serif;
	margin:0;
	padding:0;
	font-size:16px;
	font-weight:300;
	
}
a{
	font-family: 'Alegreya Sans', arial, sans-serif;
	color:$color-dark;
}


#preloader {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:#000;
	z-index:99;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items:center;

	.loader {
		border: 8px solid #f3f3f3;
		border-top: 8px solid #999;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		animation: spin 1s linear infinite;
	}

}

#page{
	height:100%;
}

#header{

	background:$background-lighter;
	position: fixed;
	width:100%;
	height:65px;
	z-index: 99;
	transition: all 0.5s ease;
	top:-4rem;
	box-shadow: 0px 0px 30px #999;

	@media screen and (max-width: $break-md) {
		top:0;
	}

	#menu-primary{

		nav{
			width: 100%;
			margin: auto;
			height: 65px;

			.logo{
				width: 220px;
				height: 34px;
				position: absolute;
				top: 1rem;
				left: 50%;
				margin-left: -110px;

				@media screen and (max-width: $break-sm) {
					left:1rem;
					margin-left:0;
				}

				img{
					width: 220px;
				}
			}

			ul.first-nav{
				transform: translate(-100%,-50%);
				padding-right: 130px;
				left: 50%;
			}
			ul.second-nav{
				transform: translate(100%,-50%);
				padding-left: 130px;
				right: 50%;
			}
			ul{
				top: 2rem;
				padding: 0;
				margin:0;
				position: absolute;
				transition: all 0.5s ease;

				@media screen and (max-width: $break-md) {
					display: none;
				}

				li {
					display: inline-block;
					vertical-align: middle;
					margin: 0 15px;

					a{
						text-transform: uppercase;
						font-weight: 500;
						font-size: 0.9rem;
						color: $color-dark;
						text-decoration: none;
						transition: color .3s ease;
						letter-spacing: 0.125em;

						&:hover, &.active{
							text-decoration: none;
							color:lighten($color-dark,60)
						}
					}


				}
			}
		}
		.social{
			position:absolute;
			right: 1rem;
			top:1rem;
			@media screen and (max-width: $break-md) {
				display: none;
			}
			li{
				display: inline-block;
				margin-left:5px;

			}
		}
	}

	#menu-alt-link{
		position:absolute;
		right:1rem;
		top:1rem;
		display:none;
		@media screen and (max-width: $break-md) {
			display: block;
		}
	}
}



#header.active{
	top:0;
}

#menu-alt{
	position:fixed;
	left:0;
	top: 65px;
	height:100%;
	width: 100%;
	background:$background-lighter;
	display:none;
	z-index:99;


	&.active{
		display: block;
	}


	ul{
		height:100%;
		padding: 0;
		margin:0;
		transition: all 0.5s ease;

		li {
			display: block;
			text-align:center;
			height:calc((100% - 65px) / 7);
			width:100%;
			border-top:1px solid #eee;

			a{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items:center;
				height: 100%;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 1.2rem;
				color: #161a21;
				text-decoration: none;
				transition: color .3s ease;
				letter-spacing: 0.125em;

				&:hover, &.active{
					text-decoration: none;
					color:lighten($color-dark,60)
				}
			}

			.instagram{
				width:50%;
				float: left;
			}
			.facebook{
				width:50%;
				float: right;
			}
		}
	}


	.social{
		border-top:1px solid #eee;
		ul{
			list-style: none;
			margin:0;
			padding:0;
			clear:both;

			li{
				width:49%;
				height: 65px;
				display: inline-block;
				text-align:center;

				a{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items:center;
					height: 100%;
				}
			}

			li:nth-of-type(1){
				border-right:1px solid #eee;
			}

		}
	}
}


#accueil{
	background:$background-dark;
	overflow:hidden;
	height:100%;
	width: 100%;
	padding: 0;
	position:relative;
	.hero{
		position:absolute;
		left:0;
		top: 0;
		height:100%;
		width: 100%;
		background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQYV2NkYGD4D8SMQAwGcAY2AbBKDBUAVuYCBX45Xb8AAAAASUVORK5CYII=) repeat rgba(0,0,0,0.4);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 4;

		.logo{

			#png-logo{
				transition: all 0.3s ease;
				display: none;

				img{
					width: 250px;

					@media screen and (max-height: 420px) {
						width: 125px;

					}
				}
				
			}
			

			#svg-logo{
				transition: all 0.3s ease;
				// display: none;
				width: 250px;
				
				@media screen and (max-height: 420px) {
					width: 125px;
				}

				svg{
					width: 250px;
					@media screen and (max-height: 420px) {
						width: 125px;
					}
					
				}


				#logo * {
					fill-opacity: 0;
					transition: fill-opacity 1s;
				}

				#logo.finished * {
					fill-opacity: 1;
				}

				&.active{
					opacity: 1;
				}

			}
		}

		.info{

			.text{
				width: 100%;
				text-align: center;

				p:nth-of-type(1){
					font-size: 2.2rem;
					font-weight: 400;
					line-height: 150%;
					color:$color-light;
					margin:0;
				}
				p:nth-of-type(2){
					font-size: 2.2rem;
					font-weight: 400;
					line-height: 150%;
					color:$color-alt;
					margin:0;
				}
				p:nth-of-type(3){
					border-top: 1px solid rgba(255,255,255,0.25);
					border-bottom: 1px solid rgba(255,255,255,0.25);
					font-size: 2rem;
					font-weight: 500;
					color:$color-light;
					line-height: 2.5rem;
					margin: 1rem 0;
					padding:1rem 0;

					span{
						font-weight: 400;
						font-size: 2.5rem;
						color:$color-alt;
					}
				}
			}
		}

		.useful{
			position:absolute;
			bottom:2rem;
			left:0;
			text-align: center;
			width:100%;

			@media screen and (max-width: $break-sm) {
				bottom:1rem;
			}

			.opening{
				p:nth-of-type(1){
					font-size: 2.2rem;
					font-weight: 300;
					line-height: 150%;
					color:$color-alt;
					margin:0;
				}

				p:nth-of-type(2){
					font-size: 2.2rem;
					font-weight: 300;
					line-height: 150%;
					color:$color-light;
					margin:0;
				}
			}

			.booking{
				padding:1rem 0 0;
				p:nth-of-type(1){
					font-size: 2.2rem;
					font-weight: 300;
					line-height: 150%;
					color:$color-alt;
					margin:0;

					@media screen and (max-width: $break-sm) {
						font-size: 1.8rem;
					}
				}
				p:nth-of-type(2){
					
					margin:0;
					a{
						font-weight: 300;
						font-size: 3rem;
						color:$color-light;
						
						&:hover{
							text-decoration: none;
						}

						@media screen and (max-width: $break-sm) {
							font-size: 2rem;
						}
					}
				}
			}

			.social{
				display: block;
				width: 100%;
				text-align: center;
				padding:0;
				ul{
					margin:0;
					padding:0;
					li{
						display: inline-block;
						img{
							width: 48px;
						}

					}
				}
			}
		}
	}

	#video{ 
		min-width: 100%;
		min-height: 100%;
		background-size: 100% 100%;
		background:$background-dark;
		object-fit: cover;
		z-index:3;
	}
}


section{
	padding:10rem 0;

	@media screen and (max-width: $break-md) {
		padding:4rem 0;
		min-height:auto;
	}

	.text{
		h1, h2{
			
			width: 100%;
		}

		p{
			line-height: 175%;
			font-size: 1.4rem;
			font-weight: 300;

			@media screen and (max-width: $break-sm) {
				margin-left:0;
				font-size: 1.2rem;
			}

			strong{
				font-weight: 300;
			}

		}

		p:nth-of-type(1){
			font-size: 1.8rem;
			font-weight: 100;
			margin: 2rem 3rem;

			@media screen and (max-width: $break-sm) {
				margin:1rem 0 0;
				font-size: 1.3rem
			}
		}
	}
}


section.with-drawing{
	background:$background-lighter;
	@media screen and (max-width: $break-md) {
		min-height: auto;
	}
	.text{
		.inner{
			max-width: 720px;
			margin:auto;

			h1{
				color: $color-dark;
				border-bottom: 1rem solid $color-alt-light;
				padding-bottom: 2rem;
				display: inline-block;
				width: auto;

				@media screen and (max-width: $break-sm) {
					padding-bottom: 1rem;
				}

				span:nth-of-type(1){
					font-size: 2.5rem;
					font-weight: 400;
					display: block;
					text-transform: uppercase;
					margin-bottom:0.5rem;
					color: $color-dark;

					@media screen and (max-width: $break-sm) {
						font-size: 2rem;
					}
				}
				span:nth-of-type(2){
					font-size: 7rem;
					display: block;
					font-weight: 100;

					@media screen and (max-width: $break-sm) {
						font-size: 4rem;
					}
				}
			}

			p{
				color: $color-dark;
			}

			p:nth-of-type(1){
				font-weight: 300;
			}

			p:nth-of-type(2){
				margin: 2rem 4rem 0 0;
				@media screen and (max-width: $break-sm) {
					margin:1rem 0 0;
				}
			}

			a{
				color:$color-dark;
				font-weight: 300;
				text-decoration: underline;
			}
		}

	}

	.drawing{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		@media screen and (max-width: $break-sm) {
			padding: 2rem 1rem;
		}

		img{
			width: 100%;
			max-width: 720px;
			min-height: 1px;
		}
	}
}

section.equal{
	background:$background-dark;
	overflow: hidden;
	position:relative;
	padding:0;


	// @media screen and (max-width: $break-md) {
	// 	padding-bottom:2rem;
	// }

	.container-fluid{
		padding:0;

		@media screen and (max-width: $break-sm) {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	.image{
		overflow: hidden;
		width: 100%;
		height: 100%;
		background-size: cover !important;
		background-position: 50% 50% !important;
		transition: all 0.3s ease;

		img{
			opacity: 0;
			width: 100%;
		}

		@media screen and (max-width: $break-md) {
			display:none;
			
			
		}
	}

	.image-xs{
		padding:0;
		img{
			width:100%;
			// margin-bottom:1rem;
		}
	}


	.text{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
		width:100%;

		@media screen and (max-width: $break-xs) {
			padding: 2rem 1rem;
		}

		@media screen and (min-width: $break-xs) and (max-width: $break-sm) {
			padding:4rem;
		}



		.inner{
			position:relative;
			max-width: 720px;
			margin:0 auto;
			padding:4rem;
			@media screen and (max-width: $break-xs) {
				padding:0;
			}
			@media screen and (max-width: $break-sm) {
				padding:0;
			}
			@media screen and (min-width: $break-sm) and (max-width: $break-sm) {
				padding:0 4rem;
			}
		}

		h1{
			text-transform: uppercase;
			display: inline-block;
			width: auto;
			padding:2rem 0;
			border-bottom:1px dashed $color-alt;
			border-top:1px dashed $color-alt;
			line-height: 3rem;

			@media screen and (min-width: $break-sm) and (max-width: $break-lg) {
				padding:1.5rem 0;
				line-height: 2.5rem;
			}

			@media screen and (max-width: $break-sm) {
				border-top:none;
				padding-bottom:1rem;
				padding-top:0;
				padding-left:0;
				padding-right:0;
				line-height: 2.25rem;
			}

			span:nth-of-type(1){
				font-size: 3rem;
				font-weight: 100;
				color: $color-light;

				@media screen and (min-width: $break-sm) and (max-width: $break-lg) {
					font-size: 2.5rem;
				}

				@media screen and (max-width: $break-sm) {
					font-size: 2.25rem;
				}

			}

			span:nth-of-type(2){
				margin-left: 2rem;
				font-size: 4rem;
				display: block;
				font-weight: 100;
				color: $color-alt;

				@media screen and (min-width: $break-sm) and (max-width: $break-lg) {
					font-size: 3.25rem;
				}

				@media screen and (max-width: $break-sm) {
					margin-left: 0;
					font-size: 3rem;
				}


			}
			
		}

		p:nth-of-type(1){
			font-size: 1.6rem;
			font-weight: 100;
			margin: 2rem 0  2rem 3rem;
			color:$color-light;

			@media screen and (min-width: $break-sm) and (max-width: $break-lg) {
				font-size: 1.3rem;
			}

			@media screen and (max-width: $break-sm) {
				margin-top:1rem;
				margin-bottom:0;
				margin-right:0;
				margin-left:0;
				font-size: 1.2rem;
			}

			
		}

		p:nth-of-type(2){
			font-size: 1.5rem;
			font-weight: 100;
			margin: 2rem 4rem 0 0;
			color: $color-alt;

			@media screen and (min-width: $break-sm) and (max-width: $break-lg) {
				font-size: 1.25rem;
			}

			@media screen and (max-width: $break-sm) {
				margin-top:1rem;
				margin-bottom:0;
				margin-right:0;
				margin-left:0;
				font-size: 1.125rem;
			}


		}

	}
}

#intro{
	.drawing{
		@media screen and (max-width: $break-sm) {
			padding-top: 0;
			padding-bottom: 2rem;
		}
	}
}

#carte{
	background:$background-light;
	text-align: center;
	color: $color-dark;

	.text{
		text-align:center;
		display: inline-block;
		h1{
			display: inline-block;
			width: auto;
			text-align: center;
			font-size:5rem;
			color:$color-dark;
			font-weight: 300;
			border-bottom: 1rem solid $color-alt-light;
			margin-bottom: 4rem;

			@media screen and (max-width: $break-sm) {
				font-size:4rem;
				margin-bottom: 2rem;
			}
		}
	}


	.menus{
		text-align:center;


		.menu{
			background:$background-lighter;

			text-align: center;
			margin: 15px;
			padding: 2rem;

			@media screen and (max-width: $break-sm) {
				padding:2rem 0;
				margin: 0;
				padding:1rem;
			}

			hr{
				width: 25%;
				border: none;
				padding-bottom: 0rem;
				border-bottom: 2px solid;
				border-top: 2px solid;
				height: 2px;
				margin-bottom: 2rem;

				@media screen and (max-width: $break-sm) {
					margin-bottom: 1rem;
					display: none;
				}
			}

			>ul{
				>li{
					margin-bottom:2rem;	
					@media screen and (max-width: $break-sm) {
						margin-bottom:1rem;	
						border:1px dashed $color-alt-light;
						padding:2rem 1rem 1rem 1rem;
					}
				}
			}

			>ul:last-of-type{
				>li:last-of-type{
					@media screen and (max-width: $break-sm) {
						margin-bottom:0;	
					}
				}
			}

			ul{
				list-style: none;
				margin:0;
				padding:0;

				li{
					font-size: 1.2rem;
					color: $color-dark;
					strong{
						font-size: 1.5rem;
						font-weight: 400;
						margin: 1rem 0;
						display: block;
					}
				}

			}
			p{
				margin:0;
			}

			h1{
				font-size:3.5rem;
				font-weight: 300;
				margin-bottom: 2rem;
				display: inline-block;
				border-bottom:0.5rem solid $background-light;

				@media screen and (max-width: $break-sm) {
					font-size:2.5rem;
					margin-bottom: 1.5rem;
				}
			}

			h2{
				font-size:1.75rem;
				font-weight: 400;
				line-height: 150%;
				margin-bottom: 1rem;
				text-transform: uppercase;

				@media screen and (max-width: $break-sm) {
					font-size:1.5rem;
				}
			}

			h3{
				font-size:1.75rem;
				font-weight: 300;
			}
		}

		#menu-soir{
			@media screen and (max-width: $break-sm) {
				margin-top:1rem;
			}

			ul>li:nth-of-type(2){
				@media screen and (max-width: $break-sm) {
					display: none;
				}
			}
		}
	}
}

#offrir{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.text{

		h1{
			border-bottom:0;
			padding-bottom:0;

		}


		p:nth-of-type(1){
			font-size: 2.5rem;
			margin-left: 2rem;
			line-height: 150%;

			@media screen and (max-width: $break-sm) {
				margin-left:0;
				font-size: 1.5rem;
			}
		}

		p:nth-of-type(2){
			margin-left: 3.5rem;
			@media screen and (max-width: $break-sm) {
				margin-left:0;
			}
		}

		p:nth-of-type(3){
			font-weight: 400;
			font-size: 1.7rem;
			margin-left: 3.5rem;
			margin-top: 2rem;
			@media screen and (max-width: $break-sm) {
				margin-left:0;
				margin-top: 1rem;
				font-size: 1.5rem;
				margin-bottom:0;
			}
		}
	}

	.drawing{
		@media screen and (max-width: $break-sm) {
			padding-top: 2rem;
			padding-bottom: 0;
		}
	}
}

#contact{
	background:$background-light;

	.title{
		text-align: center;
		h1{
			font-size:5rem;
			color:$color-dark;
			font-weight: 300;
			border-bottom: 1rem solid $color-alt-light;
			display: inline-block;
			width: auto;
			margin:0 auto 5rem auto;

			@media screen and (max-width: $break-sm) {
				font-size:3rem;
				margin-bottom:2rem;
			}
		}
	}
	
	#contact-form{
		background:$background-lighter;
		width: 100%;
		height: 100%;
		margin:auto;
		text-align: center;
		padding:2rem;
		border:1px solid $color-alt-light;


		@media screen and (max-width: $break-sm) {
			margin-top:2rem;
			height: auto;
		}

		input, textarea {
			font-size: 1rem;
			font-weight: 100;
			width: 100% ;
			color: $color-dark ;
			border:none;
			border: 1px solid $color-alt-light;
			padding:1rem;
			appearance: none;
			margin-bottom: 2rem;
			text-align: left;
			background: $background-lighter;
			border-radius: 0;
			outline: none;
			box-shadow:none;
		}

		textarea{
			height: 22rem;
			@media screen and (max-width: $break-sm) {
				margin-bottom: 0;
			}

		}

		input[type="submit"] {
			font-family: 'Alegreya Sans', sans-serif;
			-webkit-appearance: button;
			cursor: pointer;
			font-weight: 400;
			background:$background-buttons;
			color: $color-light;
			text-align:center;
			font-size: 1.25rem;
			letter-spacing: 0.0325em;
			border:none;
			padding:1rem;
			transition: background 0.5s ease;
			margin-bottom: 0;
			@media screen and (max-width: $break-sm) {
				margin-top:1rem;
				margin-bottom:0;
			}
		}

		input[type="submit"]:hover, form [type="submit"]:focus {
			background: darken($background-buttons,10%);
			cursor: pointer;

		}


		*::-webkit-input-placeholder {
			color: #999;
		}

		[placeholder]:focus::-webkit-input-placeholder {
			transition: opacity 0.5s ease;
			opacity: 0;
		}

		*:-moz-placeholder {
			color: #999;
		}

		[placeholder]:focus::-moz-placeholder {
			transition: opacity 0.5s ease;
			opacity: 0;
		}

		*::-moz-placeholder {

			color: #999;
		}

		*:-ms-input-placeholder {

			color: #999;
		}

		[placeholder]:focus::-ms-input-placeholder {
			transition: opacity 0.5s ease;
			opacity: 0;
		}



		#contact-form-message{
			display: none;
			padding: 1rem;
			text-align: center;
			font-size: 1.2rem;
			margin-bottom:2rem;

			@media screen and (max-width: $break-sm) {
				margin: 1rem 0 0;
			}


			&.error{
				background:#e26e40;
				color: $color-light;
				font-size: 1.2rem;
				h2{
					font-size: 1.4rem;
					font-weight: 500;
				}
				ul{
					margin:0;
					padding:0;
					list-style: none;
				}
			}

			&.success{
				background:#29683d;
				color: $color-light;
			}
		}
	}

	.infos{
		background:$background-lighter;
		border:1px solid $color-alt-light;
		padding:2rem;
		height: 100%;

		.contact-info{
			text-align: center;

			h1:nth-of-type(1){
				margin:auto;
				background:url(../images/logo-nav.png) no-repeat;
				width: 220px;
				height:34px;
				text-indent: -90000px;
				margin-bottom: 1rem;
			}

			address{
				font-style: normal;
				color:$color-dark;
				font-size: 1.5rem;
			}

			a.mail{
				display: block;
				width: auto;
				font-size: 1.5rem;
				color: $color-dark;
				font-weight: 300;
				text-decoration: none;
				border-top:1px dashed $color-alt-light;
				padding-top:1rem;
			}

			a.tel{
				display: block;
				font-size: 3rem;
				font-weight: 400;
				text-decoration: none;
				color: $color-dark;
				margin-top: 1rem;
				padding:2rem 0;
				border-top:1px dashed $color-alt-light;
				border-bottom:1px dashed $color-alt-light;

				@media screen and (max-width: $break-sm) {
					font-size: 2.5rem;
				}
			}
		}

		.opening-info{
			text-align:center;
			margin: 2rem 0;

			strong{
				display: block;
				font-size: 2rem;
				font-weight: 400;
				margin-bottom:1rem;
			}

			p{
				font-size: 1.5rem;
				font-weight: 300;
				margin:0;
				padding:0;
				color: $color-dark;
				line-height: 175%;
			}
		}
	}

	#map-container{
		width: 100%;
	}

	#map-container.active:before {
		width: 0;
	}

	#map{
		img{
			width:100%;
		}
	}
}




footer{
	text-align:center;
	background:$background-dark;
	padding:1rem 0 0;
	font-weight:100;
	@media screen and (max-width: $break-md) {
		padding:0;
	}


	.credits{
		text-align:right;
		margin-top:0.5rem;
		color:$color-light;
		opacity: 0.8;

		@media screen and (max-width: $break-md) {
			text-align: center;
			padding-top: 0.5rem;
			border-top:1px solid lighten($background-dark,5%);
			margin:0;
		}

		img{
			vertical-align: baseline;
			margin-left:0.25rem;
		}
	}

	.copyright{
		color:$color-light;
		opacity: 0.5;
		text-align: center;

		@media screen and (max-width: $break-md) {
			margin:0.5rem 0;
		}

		img{
			vertical-align: baseline;
			margin-left:0.25rem;
		}
	}

	.logo{
		text-align: center;
	}

	.social{
		display: block;
		text-align: left;
		margin-top:0.5rem;
		@media screen and (max-width: $break-md) {
			text-align: center;
			margin: 1rem 0;
		}
		ul{
			margin:0;
			padding:0;
			li{
				display: inline-block;
			}
			li:nth-of-type(2){
				margin-left:1rem;
			}
		}
	}
}

//Bootstrap
.row-eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display:         flex;
}